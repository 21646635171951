import React, { useEffect, useRef, useState } from "react";
import WindowAnimation from "../frontend/WindowAnimation";

interface Props {
  animate: boolean;
}

export default function Header(props: Props) {
  const { animate } = props;

  const ref = useRef<HTMLElement>();
  const [className, setClassName] = useState("");

  const startAnimation = () => {
    const elem = ref.current;
    const container = elem.parentNode.parentNode as HTMLElement;
    let headerHeight = elem.offsetHeight;
    let top = WindowAnimation.elementPosition(container)[1];
    let bottom = top + container.offsetHeight - headerHeight;

    return WindowAnimation.register({
      resize: function () {
        headerHeight = elem.offsetHeight;
        top = WindowAnimation.elementPosition(container)[1];
        bottom = top + container.offsetHeight - headerHeight;
      },
      scroll: (pos) => {
        if (pos >= bottom) {
          setClassName("bottom");
        } else if (pos >= top) {
          setClassName("fixed");
        } else {
          setClassName("");
        }
      }
    });
  };

  useEffect(() => {
    if (animate) {
      const handle = startAnimation();
      return () => {
        WindowAnimation.clear(handle);
      };
    }
  }, [animate]);

  return (
    <header className={className} ref={ref}>
      <div className="inner">
        <h1 className="logo">
          <a href="/">Anyone</a>
        </h1>
        <div className="contact">
          <h2>Kontakt</h2>
          <p>
            <a href="mailto:hello@anyone.no">hello@anyone.no</a>
            <br />
            <a href="http://facebook.com/anyone-oslo">Facebook</a>
            <br />
            <a href="http://instagram.com/anyoneoslo">Instagram</a>
          </p>
          <p>
            Anyone AS
            <br />
            c/o Factory Tøyen
            <br />
            Kjølberggata 21, 0653 Oslo
          </p>
        </div>
      </div>
    </header>
  );
}
